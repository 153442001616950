import React, { FC } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import BasicCard from 'components/BasicCard';

import { BannerProps } from './models';

import './Banner.scss';

const Banner: FC<BannerProps> = ({
  data: { topText, title, subTitle, body, image, mobileImage, cssExtraClass, link },
}) => {
  const basicCard = { title, subTitle, body };
  const ALACTA_BANNER = 'alacta-banner';
  const BANNER_IMAGE = 'image';
  const LINK = 'link';

  return (
    <div className={classnames(ALACTA_BANNER, cssExtraClass)}>
      {topText ? (
        <DangerouslySetInnerHtml
          html={topText}
          className={classnames(`${ALACTA_BANNER}__flag`, 'text-center')}
        />
      ) : null}
      {link ? (
        <Link to={link.url} className={classnames(LINK, `${LINK}__image`)}>
          <Image
            imageData={mobileImage}
            alt={mobileImage.altText}
            className={classnames(`${LINK}__image--mob`, 'd-md-none')}
          />
          <Image
            imageData={image}
            alt={image.altText}
            className={classnames(`${LINK}__image--desk`, 'd-none d-md-block')}
          />
        </Link>
      ) : (
        <div className={classnames(BANNER_IMAGE)}>
          <Image
            imageData={image}
            alt={image.altText}
            className={classnames(`${BANNER_IMAGE}--desk`, 'd-none d-md-block')}
          />
          <Image
            imageData={mobileImage}
            alt={mobileImage.altText}
            className={classnames(`${BANNER_IMAGE}--mob`, 'd-md-none')}
          />
        </div>
      )}

      {link ? (
        <Link to={link.url} className={classnames(LINK, `${LINK}__card`)}>
          <BasicCard data={basicCard} />
        </Link>
      ) : (
        <BasicCard data={basicCard} />
      )}
    </div>
  );
};

export default Banner;
