import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { BasicCardProps } from './models';

const BasicCard: FC<BasicCardProps> = ({ data: { title, subTitle, body } }) => (
  <div className="card">
    {title ? <DangerouslySetInnerHtml html={title} className="card__title" /> : null}
    {subTitle ? <DangerouslySetInnerHtml html={subTitle} className="card__subtitle" /> : null}
    {body ? <DangerouslySetInnerHtml html={body} className="card__body" /> : null}
  </div>
);

export default BasicCard;
