import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import Banner from '../../components/Banner';
import { getYoutubeId } from '../../utils/functions';
import { ProductProps } from './models';

import './Product.scss';

const Product: FC<{ data: ProductProps }> = ({
  data: {
    product: {
      banner,
      cssExtraClass,
      description,
      descriptionTitle,
      legalText,
      logo,
      pageTitle,
      presentationsDescription,
      presentationsImage,
      presentationsImageMobile,
      presentationsSecondaryImage,
      presentationsTitle,
      productDescription,
      productImage,
      secondaryImage,
      seo,
      thirdImage,
      urls,
      youtubeVideoOne,
      youtubeVideoTwo,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  const { youtubeLink: youtubeLinkOne, image: youtubeImageOne } = { ...youtubeVideoOne };
  const { youtubeLink: youtubeLinkTwo, image: youtubeImageTwo } = { ...youtubeVideoTwo };

  const [showModal, setShowModal] = React.useState(false);

  const [videoId, setVideoId] = React.useState('');

  const showYoutubeVideo = (videoUrl: string) => {
    if (!videoUrl) return;
    setVideoId(getYoutubeId(videoUrl));
    setShowModal(true);
  };

  const hideModal = () => setShowModal(false);
  const ALACTA_PRODUCT = 'alacta-product';
  const PRODUCT_DESCRIPTION = 'product-description';
  const WHY_ALACTA = 'why-alacta';
  const PRESENTATIONS = 'presentations';
  const TITLE = '__title';
  const CONTENT = '__content';

  return (
    <Layout headerTransparent alterLogo={logo || null}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />

      <div className={classnames(ALACTA_PRODUCT, cssExtraClass, 'overflow-hidden')}>
        {banner ? <Banner data={banner} /> : null}

        {pageTitle ? (
          <h2 className={classnames(`${ALACTA_PRODUCT}${TITLE}`, 'col-12')}>{pageTitle}</h2>
        ) : null}

        <section className={classnames(`${ALACTA_PRODUCT}${CONTENT}`, cssExtraClass)}>
          <div className={classnames(`${ALACTA_PRODUCT}${CONTENT}--videoLeft`, cssExtraClass)}>
            <div className="video">
              {youtubeVideoOne && youtubeImageOne && youtubeLinkOne ? (
                <button
                  type="button"
                  onClick={() => showYoutubeVideo(youtubeLinkOne)}
                  aria-label={youtubeVideoOne.ariaLabel}
                  className="button border-0 overflow-hidden px-0"
                >
                  <Image
                    imageData={youtubeImageOne}
                    alt={youtubeImageOne.altText}
                    className="image"
                  />
                </button>
              ) : null}
            </div>
          </div>

          <div className={classnames(`${ALACTA_PRODUCT}${CONTENT}--imageLeft`, cssExtraClass)}>
            {secondaryImage ? (
              <Image imageData={secondaryImage} alt={secondaryImage.altText} className="image" />
            ) : null}
          </div>

          <div className={classnames(`${ALACTA_PRODUCT}${CONTENT}--imageHexagon`, cssExtraClass)}>
            {thirdImage ? (
              <Image imageData={thirdImage} alt={thirdImage.altText} className="image" />
            ) : null}
          </div>

          <div className={classnames(`${ALACTA_PRODUCT}${CONTENT}--videoRight`, cssExtraClass)}>
            <div className="video">
              {youtubeVideoTwo && youtubeImageTwo && youtubeLinkTwo ? (
                <button
                  type="button"
                  onClick={() => showYoutubeVideo(youtubeLinkTwo)}
                  aria-label={youtubeVideoTwo.ariaLabel}
                  className="button border-0 overflow-hidden px-0"
                >
                  <Image
                    imageData={youtubeImageTwo}
                    alt={youtubeImageTwo.altText}
                    className="image"
                  />
                </button>
              ) : null}
            </div>
          </div>

          <div className={classnames(`${ALACTA_PRODUCT}${CONTENT}--product`, cssExtraClass)}>
            <div className={classnames(`${PRODUCT_DESCRIPTION}__image`)}>
              {productImage ? (
                <Image imageData={productImage} alt={productImage.altText} className="image" />
              ) : null}
            </div>

            <div
              className={classnames(
                `${PRODUCT_DESCRIPTION}__description`,
                'text-center text-md-start'
              )}
            >
              {productDescription ? <DangerouslySetInnerHtml html={productDescription} /> : null}
            </div>
          </div>

          <Modal show={showModal} onHide={hideModal} size="lg">
            <Modal.Header closeButton />
            <Modal.Body className="w-100 p-0">
              {videoId ? <YouTubeLazyLoaded videoId={videoId} autoplay /> : null}
            </Modal.Body>
          </Modal>
        </section>

        <section className={classnames(WHY_ALACTA, cssExtraClass, 'col-12')}>
          {descriptionTitle ? (
            <DangerouslySetInnerHtml
              html={descriptionTitle}
              element="h2"
              className={classnames(`${WHY_ALACTA}${TITLE}`)}
            />
          ) : null}

          {description ? <DangerouslySetInnerHtml html={description} /> : null}
        </section>

        <section className={classnames(PRESENTATIONS, cssExtraClass, 'col-12')}>
          {presentationsTitle ? (
            <h2 className={classnames(`${PRESENTATIONS}${TITLE}`)}>{presentationsTitle}</h2>
          ) : null}
          <div className={classnames(`${PRESENTATIONS}__products ${cssExtraClass}`)}>
            {presentationsImageMobile ? (
              <Image
                imageData={presentationsImageMobile}
                alt={productImage.altText}
                className={classnames(
                  `${PRESENTATIONS}__products--mob ${cssExtraClass}`,
                  'd-md-none text-center'
                )}
              />
            ) : null}
            {presentationsImage ? (
              <Image
                imageData={presentationsImage}
                alt={presentationsImage.altText}
                className={classnames(
                  `${PRESENTATIONS}__products--desk ${cssExtraClass}`,
                  'd-none d-md-block text-center'
                )}
              />
            ) : null}
            <div className={classnames(`${PRESENTATIONS}__addDha ${cssExtraClass}`)}>
              {presentationsSecondaryImage ? (
                <Image
                  imageData={presentationsSecondaryImage}
                  alt={presentationsSecondaryImage.altText}
                  className="image col-md-4"
                />
              ) : null}
              {presentationsDescription ? (
                <DangerouslySetInnerHtml html={presentationsDescription} className="text p-5" />
              ) : null}
            </div>
          </div>
        </section>

        {legalText ? (
          <DangerouslySetInnerHtml
            element="section"
            html={legalText}
            className="disclaimer col-12 px-5"
          />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    product(url: { eq: $url }) {
      banner {
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        mobileImage {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        topText
        title
        subTitle
        body
        link {
          name
          udi
          url
          icon
          queryString
        }
        cssExtraClass
      }
      cssExtraClass
      description
      descriptionTitle
      legalText
      logo {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      pageTitle
      presentationsDescription
      presentationsImage {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      presentationsImageMobile {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      presentationsSecondaryImage {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      presentationsTitle
      productDescription
      productImage {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      secondaryImage {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      seo {
        ...SEOStructureFragment
      }
      thirdImage {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      urls {
        lang
        href
      }
      youtubeVideoOne {
        ariaLabel
        youtubeLink
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
      youtubeVideoTwo {
        ariaLabel
        youtubeLink
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
  }
`;

export default Product;
